<template>
    <div id="apipe-cloud-course-page">
      <top-nav-new />
      <div class="apipe-cloud-course-container-madeSolution">
        <div class="pc-banner d-none d-lg-block">
          <div class="container" style="text-align: left">
            <h1 style="margin-bottom: 15px; font-size: 42px; font-weight: 700">
                优制云智慧园区IBMS
            </h1>
            <p class="tit" style="line-height:2rem;">
                    优制云工业互联网平台推出的智慧园区IBMS系统，是专为园区企业设计的专业级管理平台。本<br>系统深度聚焦并解决园区管理面临的信息孤岛、效率低下等核心痛点，通过集成智能监控、能效<br>优化、安全预警及综合服务调度等功能模块，实现园区资源的全面整合与智能化运营。优制云<br>IBMS以数据为驱动，提供可视化决策支持，强化安全管理，降低运营成本，提升服务响应速度<br>与质量，为园区企业的可持续发展奠定坚实基石。
            </p>
          </div>
        </div>
  
        <div class="mobile-banner d-block d-lg-none">
          <div class="container" style="text-align: left">
            <div class="title">优制云智慧园区IBMS</div>
            <p class="tit">
                优制云工业互联网平台集成供应链ISC，通过智能资源配置、高效生产管理、精准预测管理，助力企业精准把控生产节奏。费用管控与销售管理无缝对接，提升资金周转率。库存实时可视化，避免积压与短缺。应收应付清晰管理，成本核算精准高效。采购与物料计划智能协同，固定资产与总账全面掌控。优制云ISC，让中小微企业的供应链管理更智能、更高效、更透明。
            </p>
          </div>
        </div>
  
        <!-- 行业痛点 -->
        <div class="pc-industry d-none d-lg-block">
          <div class="pro-tit">行业痛点</div>
          <div class="container text-center">
            <div class="row row-cols-1 row-cols-lg-4 g-2 g-lg-3">
              <div class="col">
                <div class="p-3">
                  <img src="@/assets/newSite/ibms/hangye1.png" alt="" />
                  <h6>设施管理困难</h6>
                  <div class="des">
                    <div>设施管理依赖于人工巡检，效率</div>
                    <div>低下</div>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="p-3">
                  <img src="@/assets/newSite/ibms/hangye2.png" alt="" />
                  <h6>能源消耗高</h6>
                  <div class="des">
                    <div>能源使用缺乏有效监控，导致能</div>
                    <div>源浪费</div>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="p-3">
                  <img src="@/assets/newSite/ibms/hangye3.png" alt="" />
                  <h6> 信息服务不便捷</h6>
                  <div class="des">
                    <div>园区内信息发布不便捷，影响工</div>
                    <div>作效率</div>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="p-3">
                  <img src="@/assets/newSite/ibms/hangye4.png" alt="" />
                  <h6>空间利用率低</h6>
                  <div class="des">
                    <div>园区空间利用率不高，资源配置</div>
                    <div>不合理</div>
                  </div>
                </div>
              </div>
            </div>
  
            <div class="row"></div>
          </div>
        </div>
        <div class="mobile-industry d-block d-lg-none">
          <div class="pro-tit">行业痛点</div>
          <div class="container text-center">
            <div class="row row-cols-1 row-cols-lg-4 g-2 g-lg-3">
              <div class="col">
                <div class="p-3">
                  <img src="@/assets/newSite/isc/hangye1.png" alt="" />
                  <h6>供应商管理困难</h6>
                  <div class="des">
                    <div>供应商评估和选择缺乏数据支持</div>
                    <!-- <div>或库存不足</div> -->
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="p-3">
                  <img src="@/assets/newSite/isc/hangye2.png" alt="" />
                  <h6>供应链透明度低</h6>
                  <div class="des">
                    <div>供应链环节信息不透明，响应</div>
                    <div>速度慢</div>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="p-3">
                  <img src="@/assets/newSite/isc/hangye3.png" alt="" />
                  <h6>库存成本高</h6>
                  <div class="des">
                    <div>库存控制不当，导致库存积压</div>
                    <div>或短缺</div>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="p-3">
                  <img src="@/assets/newSite/isc/hangye4.png" alt="" />
                  <h6>物流成本高</h6>
                  <div class="des">
                    <div>物流规划不合理，运输成本高</div>
                    <!-- <div>杂，容易出错</div> -->
                  </div>
                </div>
              </div>
            </div>
  
            <div class="row"></div>
          </div>
        </div>
  
        <!-- 解决方案 -->
        <div class="container text-center">
          <div class="pro-tit d-none d-lg-block" style="font-size: 36px">
            解决方案
          </div>
          <div
            class="pro-tit d-block d-lg-none"
            style="font-size: 20px; font-weight: 700; margin-bottom: 20px"
          >
            解决方案
          </div>
          <img
            style="width: 100%"
            src="@/assets/newSite/ibms/solution.png"
            alt=""
          />
        </div>
  
        <!-- 产品能力 -->
        <div class="pc-pro-power d-none d-lg-block">
          <div class="pro-tit" style="font-size: 36px">产品能力</div>
          <div>
            <div class="tabs container text-center">
              <div
                :class="['items', tbx == index ? 'active' : '']"
                v-for="(item, index) in tabListNew"
                :key="index"
                @click="handleTab(index)"
              >
                <img :src="item.img1" />
                <div class="tit">{{ item.tit }}</div>
              </div>
            </div>
            <div class="tab-cot">
              <div class="container text-center">
                <div class="row" v-show="tbx == 0">
                  <div class="col-xs-12 col-md-6 lf">
                    <div class="mtit">
                        行政区划管理、机构管理、项目管理、楼栋管理、房屋管理
                    </div>
                    <div class="mcot">
                      <div>
                        支持维护业务基础数据，包括：行政区划、园区管理机构、项目、楼栋、房间等信息的维护；支持项目级系统参数配置；支持对角色进行项目级数据权限授权
                      </div>
                    </div>
                    <div style="text-align: left">
                      <el-button
                        @click="handleTest"
                        type="primary"
                        round
                        class="btn btn-primary btn-sm"
                      >
                        免费试用
                      </el-button>
                    </div>
                  </div>
                  <div
                    class="col-xs-12 col-md-6"
                    style="text-align: center; padding: 10px"
                  >
                    <img
                      src="@/assets/newSite/ibms/c.png"
                      alt=""
                      style="width: 90%"
                    />
                  </div>
                </div>
                <div class="row" v-show="tbx == 1">
                  <div class="col-xs-12 col-md-6 lf">
                    <div class="mtit">工作台</div>
                    <div class="mcot">
                      <div>
                        展示房间即时的经营情况；支持对房间的预留；支持对外发布房间信息
                      </div>
                    </div>
                    <div style="text-align: left">
                      <el-button
                        @click="handleTest"
                        type="primary"
                        round
                        class="btn btn-primary btn-sm"
                      >
                        免费试用
                      </el-button>
                    </div>
                  </div>
                  <div
                    class="col-xs-12 col-md-6"
                    style="text-align: center; padding: 10px"
                  >
                    <img
                      src="@/assets/newSite/ibms/c.png"
                      alt=""
                      style="width: 90%"
                    />
                  </div>
                </div>
                <div class="row" v-show="tbx == 2">
                  <div class="col-xs-12 col-md-6 lf">
                    <div class="mtit">公告管理、报事报修、设备管理、抄表管理</div>
                    <div class="mcot">
                      <div>
                        支持公告的管理和对外推送；支持对故障的提报和处理；支持仪表台账的维护；支持水电仪表的抄表日志维护以及仪表用量的统计
                      </div>
                    </div>
                    <div style="text-align: left">
                      <el-button
                        @click="handleTest"
                        type="primary"
                        round
                        class="btn btn-primary btn-sm"
                      >
                        免费试用
                      </el-button>
                    </div>
                  </div>
                  <div
                    class="col-xs-12 col-md-6"
                    style="text-align: center; padding: 10px"
                  >
                    <img
                      src="@/assets/newSite/ibms/c.png"
                      alt=""
                      style="width: 90%"
                    />
                  </div>
                </div>
                <div class="row" v-show="tbx == 3">
                  <div class="col-xs-12 col-md-6 lf">
                    <div class="mtit"> 合同管理、退租管理、大租户配置
                    </div>
                    <div class="mcot">
                      <div>
                        支持合同的创建、计费、审批；支持合同执行过程中的提前退租；支持对多房源租赁诉求的客户进行独立管理
                      </div>
                    </div>
                    <div style="text-align: left">
                      <el-button
                        @click="handleTest"
                        type="primary"
                        round
                        class="btn btn-primary btn-sm"
                      >
                        免费试用
                      </el-button>
                    </div>
                  </div>
                  <div
                    class="col-xs-12 col-md-6"
                    style="text-align: center; padding: 10px"
                  >
                    <img
                      src="@/assets/newSite/ibms/c.png"
                      alt=""
                      style="width: 90%"
                    />
                  </div>
                </div>
                <div class="row" v-show="tbx == 4">
                  <div class="col-xs-12 col-md-6 lf">
                    <div class="mtit">
                        合同费用、账单管理
                    </div>
                    <div class="mcot">
                      <div>
                        合同相关费用台账的管理；支持账单的生成、修改和下发  
                      </div>
                    </div>
                    <div style="text-align: left">
                      <el-button
                        @click="handleTest"
                        type="primary"
                        round
                        class="btn btn-primary btn-sm"
                      >
                        免费试用
                      </el-button>
                    </div>
                  </div>
                  <div
                    class="col-xs-12 col-md-6"
                    style="text-align: center; padding: 10px"
                  >
                    <img
                      src="@/assets/newSite/ibms/c.png"
                      alt=""
                      style="width: 90%"
                    />
                  </div>
                </div>
                <div class="row" v-show="tbx == 5">
                  <div class="col-xs-12 col-md-6 lf">
                    <div class="mtit">
                        驾驶舱
                    </div>
                    <div class="mcot">
                      <div>
                        提供丰富的指标，支持指标定制开发
                      </div>
                    </div>
                    <div style="text-align: left">
                      <el-button
                        @click="handleTest"
                        type="primary"
                        round
                        class="btn btn-primary btn-sm"
                      >
                        免费试用
                      </el-button>
                    </div>
                  </div>
                  <div
                    class="col-xs-12 col-md-6"
                    style="text-align: center; padding: 10px"
                  >
                    <img
                      src="@/assets/newSite/ibms/c.png"
                      alt=""
                      style="width: 90%"
                    />
                  </div>
                </div>
                <div class="row" v-show="tbx == 6">
                  <div class="col-xs-12 col-md-6 lf">
                    <div class="mtit">
                        客户管理
                    </div>
                    <div class="mcot">
                      <div>
                        支持客户预约、参观的过程跟进
                      </div>
                    </div>
                    <div style="text-align: left">
                      <el-button
                        @click="handleTest"
                        type="primary"
                        round
                        class="btn btn-primary btn-sm"
                      >
                        免费试用
                      </el-button>
                    </div>
                  </div>
                  <div
                    class="col-xs-12 col-md-6"
                    style="text-align: center; padding: 10px"
                  >
                    <img
                      src="@/assets/newSite/ibms/c.png"
                      alt=""
                      style="width: 90%"
                    />
                  </div>
                </div>
                <div class="row" v-show="tbx == 7">
                  <div class="col-xs-12 col-md-6 lf">
                    <div class="mtit">
                        推广页
                    </div>
                    <div class="mcot">
                      <div>
                        支持空置房源的对外展示
                      </div>
                    </div>
                    <div style="text-align: left">
                      <el-button
                        @click="handleTest"
                        type="primary"
                        round
                        class="btn btn-primary btn-sm"
                      >
                        免费试用
                      </el-button>
                    </div>
                  </div>
                  <div
                    class="col-xs-12 col-md-6"
                    style="text-align: center; padding: 10px"
                  >
                    <img
                      src="@/assets/newSite/ibms/c.png"
                      alt=""
                      style="width: 90%"
                    />
                  </div>
                </div>
                <div class="row" v-show="tbx == 8">
                  <div class="col-xs-12 col-md-6 lf">
                    <div class="mtit">
                        租赁情况明细表、应收未收明细表
                    </div>
                    <div class="mcot">
                      <div>
                        提供丰富的报表样式与数据分析能力，支持个性化报表的定制开发需求
                      </div>
                    </div>
                    <div style="text-align: left">
                      <el-button
                        @click="handleTest"
                        type="primary"
                        round
                        class="btn btn-primary btn-sm"
                      >
                        免费试用
                      </el-button>
                    </div>
                  </div>
                  <div
                    class="col-xs-12 col-md-6"
                    style="text-align: center; padding: 10px"
                  >
                    <img
                      src="@/assets/newSite/ibms/c.png"
                      alt=""
                      style="width: 90%"
                    />
                  </div>
                </div>
                <div class="row" v-show="tbx == 9">
                  <div class="col-xs-12 col-md-6 lf">
                    <div class="mtit">
                        收费规则配置、优管支付配置
                    </div>
                    <div class="mcot">
                      <div>
                        支持费项的收费规则自定义配置；支持商户的维护，支持费用的定向收款
                      </div>
                    </div>
                    <div style="text-align: left">
                      <el-button
                        @click="handleTest"
                        type="primary"
                        round
                        class="btn btn-primary btn-sm"
                      >
                        免费试用
                      </el-button>
                    </div>
                  </div>
                  <div
                    class="col-xs-12 col-md-6"
                    style="text-align: center; padding: 10px"
                  >
                    <img
                      src="@/assets/newSite/ibms/c.png"
                      alt=""
                      style="width: 90%"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
  
        <div class="mobile-pro-power d-block d-lg-none">
          <h4 class="pro-tit">产品能力</h4>
          <div class="">
            <div class="tabs">
              <div
                :class="['items', idx == index ? 'active' : '']"
                v-for="(item, index) in tabListNew"
                :key="index"
                @click="handleTab(index)"
              >
                <img :src="item.img1" />
                <div class="tit">{{ item.tit }}</div>
              </div>
            </div>
            <div class="tab-cot">
              <div class="container text-center">
                <div class="row" v-show="idx == 0">
                  <div class="col-xs-12 col-md-6 lf">
                    <h4 style="text-align: left">生产BOM、文件管理</h4>
                    <div
                      style="
                        text-align: left;
                        margin: 10px 0 10px 0;
                        color: #333333;
                      "
                    >
                      <div>工单生产BOM和对应资料的管理</div>
                      <div>便于管理工单资料核对生产BOM信息</div>
                    </div>
                  </div>
                  <div
                    class="col-xs-12 col-md-6"
                    style="text-align: center; padding: 10px"
                  >
                    <img
                      src="@/assets/newSite/mes/a.png"
                      alt=""
                      style="width: 100%"
                    />
                  </div>
                  <div style="text-align: left; margin-top: 10px">
                    <el-button @click="handleTest" type="primary" round>
                      免费试用
                    </el-button>
                  </div>
                </div>
  
                <div class="row" v-show="idx == 1">
                  <div class="col-xs-12 col-md-6 lf">
                    <!-- <h6>数字化办公平台——伟班</h6> -->
                    <p>
                      无纸化作业，降低企业成本 使用移动IPDA进行检验登记，方便快捷
                      网络传输，减少中间环节 加快现场响应时间，减少等待浪费
                    </p>
                    <div>
                      <!-- <button @click="handleTest" type="button" class="btn btn-primary btn-sm">
                          免费试用
                        </button> -->
                    </div>
                  </div>
                  <div
                    class="col-xs-12 col-md-6"
                    style="text-align: center; padding: 10px"
                  >
                    <img
                      src="@/assets/newSite/madeSolution/p2.png"
                      alt=""
                      style="width: 100%"
                    />
                  </div>
                </div>
                <div class="row" v-show="idx == 2">
                  <div class="col-xs-12 col-md-6 lf">
                    <!-- <h6>数字化办公平台——伟班</h6> -->
                    <p>
                      通过出货单、箱号、客户序列号，从后往前查询该产品所经工序、人、机、料、法、测等信息；
                      无纸化作业，降低企业成本 一键追溯，方便快捷
                      追溯报告网络传输，减少中间环节
                      对追溯不良品，现场及时响应，减少等待浪费
                      根据客户情况，可实现批次级或物料级追溯，方便问题定位
                    </p>
                    <div>
                      <!-- <button @click="handleTest" type="button" class="btn btn-primary btn-sm">
                          免费试用
                        </button> -->
                    </div>
                  </div>
                  <div
                    class="col-xs-12 col-md-6"
                    style="text-align: center; padding: 10px"
                  >
                    <img
                      src="@/assets/newSite/madeSolution/p3.png"
                      alt=""
                      style="width: 100%"
                    />
                  </div>
                </div>
                <div class="row" v-show="idx == 3">
                  <div class="col-xs-12 col-md-6 lf">
                    <!-- <h6>数字化办公平台——伟班</h6> -->
                    <p>
                      自动记录生产过程中的各项数据，节省人工
                      快速统计分析，并以图表形式展示，标准客观，堵截人工统计出错
                      数据展示实时，方便领导决策
                    </p>
                    <div>
                      <!-- <button @click="handleTest" type="button" class="btn btn-primary btn-sm">
                          免费试用
                        </button> -->
                    </div>
                  </div>
                  <div
                    class="col-xs-12 col-md-6"
                    style="text-align: center; padding: 10px"
                  >
                    <img
                      src="@/assets/newSite/madeSolution/p4.png"
                      alt=""
                      style="width: 100%"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
  
        <!-- 产品优势 -->
        <div class="pc-scene d-none d-lg-block">
          <div class="pro-tit d-none d-lg-block">产品优势</div>
          <div class="container text-center">
            <div class="row row-cols-1 row-cols-lg-4 g-2 g-lg-3">
              <div class="col">
                <div class="p-3">
                  <img src="@/assets/newSite/ibms/ad1.png" alt="" />
                </div>
              </div>
              <div class="col">
                <div class="p-3">
                  <img src="@/assets/newSite/ibms/ad2.png" alt="" />
                </div>
              </div>
              <div class="col">
                <div class="p-3">
                  <img src="@/assets/newSite/ibms/ad3.png" alt="" />
                </div>
              </div>
              <div class="col">
                <div class="p-3">
                  <img src="@/assets/newSite/ibms/ad4.png" alt="" />
                </div>
              </div>
            </div>
  
            <div class="row"></div>
          </div>
        </div>
        <!-- 产品优势 -->
        <div class="mobile-scene d-block d-lg-none">
          <div
            class="pro-tit d-block d-lg-none"
            style="font-size: 20px; font-weight: 700; margin-bottom: 20px"
          >
            产品优势
          </div>
          <div class="container text-center">
            <div class="row row-cols-1 row-cols-lg-4 g-2 g-lg-3">
              <div class="col">
                <div class="p-3">
                  <img src="@/assets/newSite/isc/ad1.png" alt="" />
                </div>
              </div>
              <div class="col">
                <div class="p-3">
                  <img src="@/assets/newSite/isc/ad2.png" alt="" />
                </div>
              </div>
              <div class="col">
                <div class="p-3">
                  <img src="@/assets/newSite/isc/ad3.png" alt="" />
                </div>
              </div>
              <div class="col">
                <div class="p-3">
                  <img src="@/assets/newSite/isc/ad4.png" alt="" />
                </div>
              </div>
            </div>
  
            <div class="row"></div>
          </div>
        </div>
  
        <!-- 成功案列 -->
        <section
          class="pc-product d-none d-lg-block"
          style="margin: 80px 0 50px 0"
        >
          <div class="container text-center">
            <h1 class="pro-tit" style="margin-bottom: 20px; font-size: 36px">
              成功案例
            </h1>
            <div class="row align-items-start">
              <div
                :class="['col', idx == index ? 'acticv' : '']"
                @click="handleSwitch(index)"
                v-for="(item, index) in tabs"
                :key="index"
              >
                <span>{{ item }}</span>
              </div>
            </div>
          </div>
          <div class="pro-wrap">
            <div
              class="container animate__animated animate__fadeInLeftBig"
              v-show="idx == 0"
            >
              <div class="content" style="display: flex;padding:20px 0 ;">
                <div class="wrap">
                  <h6 style="font-size: 35px">
                    {{caseA.contentTitle}}
                  </h6>
                  <p class="p-content">
                    {{caseA.contentDescription}}
                  </p>
                  <div>
                    <el-button
                type="primary" size="mini" round
                  @click="
                    () => {
                      this.openNewWindow(`/caseDetail?id=${caseA.id}&tit=${caseA.contentTitle}`);
                    }
                  "
                  class="btn btn-primary btn-sm " 
                >
                  查看详情
                </el-button>
                  </div>
                </div>
                <div style="flex: 40%; text-align: center">
                  <img :src="baseImgUrl + caseA.contentImgUrl" width="640px" height="360px"/>
                </div>
              </div>
            </div>
            <div
              class="container animate__animated animate__fadeInLeftBig"
              v-show="idx == 1"
            >
              <div class="content" style="display: flex;padding:20px 0 ;">
                <div class="wrap" style="flex: 60%">
                  <h6  style="font-size:35px;">{{caseB.contentTitle}}</h6>
                  <p class="p-content">
                    {{caseB.contentDescription}}
                  </p>
                  <div>
                    <el-button
                type="primary" size="mini" round
                  @click="
                    () => {
                      this.openNewWindow(`/caseDetail?id=${caseB.id}&tit=${caseB.contentTitle}`);
                    }
                  "
                  class="btn btn-primary btn-sm" 
                >
                  查看详情
                </el-button>
                  </div>
                </div>
                <div style="flex: 40%; text-align: center">
                  <img :src="baseImgUrl + caseB.contentImgUrl" width="640px" height="360px"/>
                </div>
              </div>
            </div>
            <div
              class="container animate__animated animate__fadeInRightBig"
              v-show="idx == 2"
            >
              <div class="content" style="display: flex;padding:20px 0 ;">
                <div  class="wrap"  style="flex: 60%">
                  <h6  style="font-size:35px;">{{caseC.contentTitle}}</h6>
                  <p class="p-content">
                    {{caseC.contentDescription}}
                  </p>
                  <div>
                    <el-button
                type="primary" size="mini" round
                  @click="
                    () => {
                      this.openNewWindow(`/caseDetail?id=${caseC.id}&tit=${caseC.contentTitle}`);
                    }
                  "
                  class="btn btn-primary btn-sm" 
                >
                  查看详情
                </el-button>
                  </div>
                </div>
                <div style="flex: 40%; text-align: center">
                  <img :src="baseImgUrl + caseC.contentImgUrl" width="640px" height="360px"/>
                </div>
              </div>
            </div>
          </div>
        </section>
  
        <section
          class="mobile-product d-block d-lg-none"
          style="margin-top: 40px"
        >
          <div class="container text-center">
            <h3 class="pro-tit">成功案例</h3>
            <div class="row align-items-start">
              <div
                :class="['col', idx == index ? 'acticv' : '']"
                @click="handleSwitch(index)"
                v-for="(item, index) in tabs"
                :key="index"
              >
                <span>{{ item }}</span>
              </div>
            </div>
          </div>
          <div class="pro-wrap">
            <div
              class="container animate__animated animate__fadeInLeftBig"
              v-show="idx == 0"
            >
              <div class="content">
                <div style="flex: 60%">
                  <h6 style="font-weight: 700; margin-bottom: 1rem">
                    优制云助力华瀚管道实现智能化生产
                  </h6>
                  <p class="p-content">
                    优制云助力华瀚管道实现智能化生产，通过智能制造MES系统的深度应用，实现了生产流程的精细化管理和智能化控制。系统优化了生产计划、实时监控设备状态、自动采集生产数据，有效提升了生产透明度，为华瀚管道带来了显著的经济效益和市场竞争优势。
                  </p>
                </div>
                <div style="text-align: center; margin-bottom: 20px">
                  <img src="@/assets/newSite/home/cases.png" />
                </div>
                <div>
                  <el-button
                    @click="
                      () => {
                        this.openNewWindow('/smartMade');
                      }
                    "
                    round
                    type="button"
                    class="btn btn-primary btn-sm"
                  >
                    了解更多
                  </el-button>
                </div>
              </div>
            </div>
            <div
              class="container animate__animated animate__fadeInLeftBig"
              v-show="idx == 1"
            >
              <div class="content">
                <div style="flex: 60%">
                  <h6 style="font-weight: bold; margin-bottom: 0.5rem">
                    智能办公
                  </h6>
                  <p class="p-content">
                    智能化：借助先进的技术和设备，智能办公能够实现智能化决策、智能化处理、智能化控制等，提高工作效率和质量。
                    高效性：能够减少繁琐的手动操作，简化工作流程，提高工作效率。
                    协同性：能够实现团队协作的高效协同，提高团队协作效率。
                    安全性：具备较高的安全性，能够保护企业和员工的隐私和数据安全。
                    移动性：支持移动办公，IM即时聊天，员工可以随时随地进行办公，提高工作效率和灵活性。
                    云端化：基于云计算技术实现，能够实现数据的云端存储、备份和恢复等功能，提高数据的安全性和可靠性。
                    数据分析：能够对各种数据进行分析和挖掘，为企业提供更全面的数据支持和决策参考。
                  </p>
                  <div class="btns" style="">
                    <el-tag type="info" style="">伟班（OA）</el-tag>
                  </div>
                  <div>
                    <button
                      @click="
                        () => {
                          this.openNewWindow('/smartOffice');
                        }
                      "
                      type="button"
                      class="btn btn-primary btn-sm"
                    >
                      了解更多
                    </button>
                  </div>
                </div>
                <div style="flex: 40%; text-align: center">
                  <img src="@/assets/newSite/home/s2.png" />
                </div>
              </div>
            </div>
            <div
              class="container animate__animated animate__fadeInRightBig"
              v-show="idx == 2"
            >
              <div class="content">
                <div style="flex: 60%">
                  <h6 style="font-weight: bold; margin-bottom: 0.5rem">
                    智慧园区
                  </h6>
                  <p class="p-content">
                    数据可视化及应用：园区通过人脸、手机一键开门，通行无卡化、安全便捷，员工app自主登记邀约，访客到访自主无感通行…
                    能耗管理：园区通过人脸、手机一键开门，通行无卡化、安全便捷，员工app自主登记邀约，访客到访自主无感通行，访客通行权限…
                    安全管理：视频上云助力园区远程巡视，降本增效；制定园区安全检查标准，通过视频巡更+图片巡更方式进行园区日常巡逻，提高管理效率…
                    设备运维：融合AIoT技术，实现对设备资产的全生命周期管理，变配电房、给排水房等机电设备智能化实时监测，降低安全隐患…
                    服务体验：园区通过人脸、手机一键开门，通行无卡化、安全便捷，员工app自主登记邀约，访客到访自主无感通行…
                    集成能力：打通园区运营系统、工单等系统，实现智能化数据与业务系统的流程自动化、全场景闭环，可将智能化应用集成至现有员工app…
                  </p>
                  <div class="btns">
                    <el-tag type="info" style="">智慧园区</el-tag>
                  </div>
                  <div>
                    <button
                      @click="
                        () => {
                          this.openNewWindow('/smartPark');
                        }
                      "
                      type="button"
                      class="btn btn-primary btn-sm"
                    >
                      免费试用
                    </button>
                  </div>
                </div>
                <div style="flex: 40%; text-align: center">
                  <img src="@/assets/newSite/home/s3.png" />
                </div>
              </div>
            </div>
          </div>
        </section>
  
        <right-nav-new class="d-none d-lg-block" />
      </div>
  
      <NewFooter />
      <MobileFooter class="d-block d-lg-none" />
    </div>
  </template>
            
    <script>
  import { Swiper, SwiperSlide } from "vue-awesome-swiper";
  import SwiperCore, { Autoplay } from "swiper";
  SwiperCore.use([Autoplay]);
  import Qs from "qs";
import { Newslist } from "@/api/fetch";
  export default {
    name: "hardware",
    data() {
      return {
        tabs: ["润玉空间", "数研时空", "华瀚管道"],
        activeName: "1",
        idx: 0,
        tbx: 0,
        ids: -1,
        tabListNew: [
          {
            img1: require("@/assets/newSite/ibms/p1.png"),
            tit: "主数据管理",
          },
          {
            img1: require("@/assets/newSite/ibms/p2.png"),
            tit: "工作台",
          },
          {
            img1: require("@/assets/newSite/ibms/p3.png"),
            tit: "智慧运营",
          },
          {
            img1: require("@/assets/newSite/ibms/p4.png"),
            tit: "租赁管理",
          },
          {
            img1: require("@/assets/newSite/ibms/p5.png"),
            tit: "财务管理",
          },
          {
            img1: require("@/assets/newSite/ibms/p6.png"),
            tit: "驾驶舱",
          },
          {
            img1: require("@/assets/newSite/ibms/p7.png"),
            tit: "数字化招商",
          },
          {
            img1: require("@/assets/newSite/ibms/p8.png"),
            tit: "推广页",
          },
          {
            img1: require("@/assets/newSite/ibms/p9.png"),
            tit: "统计报表",
          },
          {
            img1: require("@/assets/newSite/ibms/p10.png"),
            tit: "财务配置",
          }
        ],
  
        swiperOption: {
          slidesPerView: 3,
          spaceBetween: 30,
          freeMode: true,
          pagination: {
            el: ".swiper-pagination",
            clickable: true,
          },
        },
        baseImgUrl:'https://admin.veiban.com',
      caseList:[],
      caseA:{},
      caseB:{},
      caseC:{},
      };
    },
    components: {
      Swiper,
      SwiperSlide,
    },
    created() {
    this.getCase();
  },
    methods: {
              //成功案例
    getCase() {
      Newslist(
        Qs.stringify({ categoryId: '1839482945283117058', pageSize: 10, pageNo: 1,productCode:'ibms' })
      ).then((res) => {
        if (res.data.rows.length) {
            this.caseA = res.data.rows [0]
            this.caseB = res.data.rows [1]
            this.caseC = res.data.rows [2]
            this.caseList =  res.data.rows
        }
      });
    },
    openNewWindow(path) {
      const routeData = this.$router.resolve({path,id:'123456'});
      window.open(routeData.href, "_blank");
    },
      handleSwitch(idx) {
        this.idx = idx;
      },
      handleTest() {
        window.open("https://apipecloud.veiban.com/login");
      },
      handleClick(tab, event) {
        console.log(tab, event);
      },
  
      handleTab(index) {
        this.tbx = index;
      },
    },
    destroyed() {
      window.removeEventListener("scroll", this.onScroll, false);
    },
  };
  </script>
            
    <style lang="less" scoped>
  .apipe-cloud-course-container-madeSolution {
    .pc-banner {
      height: 600px;
      background-image: url("../../assets/newSite/ibms/banner.png");
      background-size: cover;
      background-repeat: no-repeat;
      .container {
        color: white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        .tit {
          font-size: 18px;
          margin: 4px 0;
        }
        .exp {
          margin-top: 2rem;
          font-size: 14px;
          border: 1px solid white;
          display: inline-block;
          width: 100px;
          padding: 2px 0;
          text-align: center;
          &:hover {
            cursor: pointer;
          }
        }
      }
    }
    .mobile-banner {
      height: 300px;
      width: 100%;
      background-image: url("../../assets/newSite/ibms/banner.png");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      .container {
        color: white;
        padding: 1rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        .title {
          font-size: 32px;
          text-align: left;
          color: white;
          font-family: Source Han Sans CN;
          font-weight: 200;
          margin-left: 0;
        }
        .tit {
          font-size: 14px;
          margin-top: 15px;
        }
        .exp {
          margin-top: 0.5rem;
          font-size: 12px;
          border: 1px solid white;
          display: inline-block;
          width: 80px;
          text-align: center;
        }
      }
    }
    .pc-industry {
      margin: 80px 0;
      .pro-tit {
        margin-bottom: 40px;
        font-size: 36px;
      }
      .col {
        .p-3 {
          background: #f6faff;
          height: 240px;
          width: 300px;
          box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
        }
      }
      img {
        width: 100px;
        height: 100px;
      }
      h6 {
        margin-top: 0.5rem;
        font-size: 16px;
        font-weight: 700;
        color: rgba(51, 51, 51, 1);
      }
      .des {
        color: #333333;
        font-size: 14px;
      }
    }
    .mobile-industry {
      margin: 40px 0;
      .pro-tit {
        margin-bottom: 20px;
        font-size: 20px;
        font-weight: 700;
        color: #333;
      }
      .col {
        .p-3 {
          background: #f6faff;
          height: 240px;
          margin: 20px;
          box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
        }
      }
      img {
        width: 100px;
        height: 100px;
      }
      h6 {
        margin-top: 0.5rem;
        font-size: 16px;
        font-weight: 500;
        color: rgba(51, 51, 51, 1);
      }
      .des {
        color: #333333;
        font-size: 14px;
      }
    }
    .pc-pro-power {
      margin: 50px 0;
      .pro-tit {
        margin-bottom: 30px;
      }
      .tabs {
        display: flex;
        .items {
          flex: 25%;
          // height: 120px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          font-size: 14px;
          .tit {
            padding: 20px 0 16px 0;
            font-size: 18px;
            font-weight: 700;
          }
          &:hover {
            cursor: pointer;
          }
          img {
            width: 36px;
            height: 36px;
          }
        }
        .active {
          .tit {
            border-bottom: 4px solid rgba(20, 132, 255, 1);
          }
        }
      }
      .tab-cot {
        background: #f6faff;
        padding: 20px;
        .row {
          min-height: 300px;
          padding: 20px 100px;
          .mtit {
            text-align: left;
            font-size: 24px;
            font-weight: 500;
          }
          .mcot {
            text-align: left;
            margin: 20px 0 30px 0;
            color: #333333;
          }
          .lf {
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            justify-content: center;
            padding: 10px;
            .btn {
              width: 120px;
              background: #1484ff;
              &:hover {
                background: #66b1ff;
              }
            }
          }
          p {
            color: #666666;
            font-size: 14px;
          }
        }
      }
    }
    .mobile-pro-power {
      margin: 50px 0;
      .pro-tit {
        margin-bottom: 30px;
        font-size: 20px;
        color: #333;
        font-weight: 700;
      }
      .tabs {
        display: flex;
        width: 375px;
        overflow-x: scroll;
        overflow-y: hidden;
        margin: 0 auto;
        .items {
          width: 33%;
          flex-shrink: 0;
          height: 120px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          font-size: 14px;
          &:hover {
            cursor: pointer;
          }
          .tit {
            padding: 20px 0 16px 0;
          }
          img {
            width: 60px;
            height: 60px;
          }
        }
        .active {
          .tit {
            border-bottom: 4px solid rgba(20, 132, 255, 1);
          }
        }
      }
      .tab-cot {
        background: #f6faff;
        padding: 20px;
        .row {
          min-height: 300px;
          padding: 10px;
          .lf {
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            justify-content: center;
            padding: 10px;
            .btn {
              width: 100px;
            }
          }
          p {
            color: #666666;
            font-size: 14px;
          }
        }
      }
    }
    .pc-scene {
      margin: 80px 0;
      .pro-tit {
        margin-bottom: 30px;
        font-size: 36px;
      }
      .col {
        .p-3 {
          min-height: 200px;
          padding: 0 !important;
        }
      }
      img {
        width: 100%;
      }
      h6 {
        font-size: 16px;
        color: rgba(51, 51, 51, 1);
      }
    }
    .mobile-scene {
      margin: 0 0;
      .pro-tit {
        margin-bottom: 30px;
        font-size: 36px;
      }
      .col {
        .p-3 {
          min-height: 200px;
          padding: 5px !important;
        }
      }
      img {
        width: 100%;
        height: 320px;
      }
      h6 {
        font-size: 16px;
        color: rgba(51, 51, 51, 1);
      }
    }

    .pc-product {
      .acticv {
        span {
          height: 4px;
          border-bottom: 4px solid #1484ff;
          color: #1484ff;
        }
      }
      .col {
        cursor: pointer;
        span {
          display: inline-block;
          height: 40px;
        }
      }
      .pro-wrap {
        background: #f6faff;
        text-align: left;
        .container {
          display: flex;
  
          .cot {
            background: url("../../assets/newSite/home/pc.png");
            background-repeat: no-repeat;
            background-size: cover;
            height: 702px;
            width: 100%;
          }
          img {
            // height: 16rem;
          }
          .content {
            margin: 40px 0;
            width: 100%;
            .wrap {
              flex: 60%;
              display: flex;
              flex-direction: column;
              justify-content: center;
              p {
                font-family: Source Han Sans CN;
                font-weight: 200;
                color: #333333;
                width: 540px;
                margin-bottom: 20px;
                margin-top: 12px;
                font-size: 18px;
              }
            }
            .btn {
              width: 120px;
              background: #1484ff;
              height: 36px;
            }
          }
        }
      }
    }
    .mobile-product {
      .acticv {
        span {
          height: 4px;
          border-bottom: 4px solid #1484ff;
          color: #1484ff;
        }
      }
      .col {
        padding: 1rem 0;
        cursor: pointer;
        span {
          display: inline-block;
          height: 40px;
        }
      }
      .pro-tit {
        font-family: Source Han Sans CN;
        font-weight: 700;
        color: #333333;
        font-size: 20px;
      }
      .pro-wrap {
        text-align: left;
        .container {
          display: flex;
          .content {
            padding: 1rem 1.5rem 1.5rem 1.5rem;
            background: white;
            width: 100%;
            img {
              width: 100%;
              // height: 20rem;
            }
            .p-content {
              color: #666;
              font-size: 0.8rem;
              font-family: "思源黑体";
              line-height: 1.6rem;
            }
            .btn {
              width: 120px;
              background: #1484ff;
              height: 36px;
              color: white;
            }
          }
        }
      }
    }
  
   
  
   
  }
  </style>
            